import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  List,
  useToast,
  Input,
  Text,
  Label,
  Select,
  Title,
  Textarea,
  Radio,
  Checkbox,
} from '@nimbus-ds/components';
import { CogIcon, LockOpenIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useHistory } from 'react-router-dom';
import { Table } from '@nimbus-ds/components';

const headers = ['Id', 'Tienda', 'Nombre', 'Email', 'Razón Social', 'Acciones'];

import axiosInstance from '../../axios';

const PageDashboard: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(true);
  const [isVinculate, setIsVinculate] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [showMessageVinculateAccount, setShowMessageVinculateAccount] =
    useState(false);
  const [errorValidateStore, setErrorValidateStore] = useState(false);
  const [error, setError] = useState(false);
  const [form, setFormData] = useState({
    nombre_tienda: '',
    descripcion: '',
    origen_producto: '',
    tipo_oferta: '',
    registro_marca: '',
    nombre: '',
    telefono: '',
    email: '',
    url_tienda: '',
  });

  interface Seller {
    store_id: string;
    name: string;
    email: string;
    razon_social: string;
    url: string;
    status: string;
  }

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    checkStatusAccount();
    //getListOfPendingSellers();
    //getListOfSellers();
  }, []);

  const handleGoToDashboard = async () => {
    push('/');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  const getDashboard = async () => {
    try {
      const r = await axiosInstance.get('/dashboard');
      console.log(r);
    } catch (e) {
      console.error(e);
    }
    return;
  };
  getDashboard();

  /*
  const handleSellerStatusChange = (e: any) => {
    console.log(e.target.value, ' - ', e.target.key, ' - ', e.target.label);
    console.log(e.target.id);
    console.log(e.key);
  };
  */

  const handleChange = (e: any) => {
    setFormData({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log('new form data ', form);
  };

  const checkStatusAccount = async () => {
    console.log('verificando vinculacion de cuenta...');
    try {
      const response = await axiosInstance.get('/check_status_account');
      console.log(response);
      if (response.data.status == true) {
        console.log('CUENTA YA VINCULADA');
        if (response.data.is_vinculate) {
          setIsVinculate(true);
        }
        if (response.data.is_approved) {
          setIsApproved(true);
        }
        setIsVerifing(false);
      }
    } catch (e) {
      console.error(e);
      setIsVerifing(false);
      setIsVinculate(false);
      setErrorValidateStore(true);
    }
    return;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  const validateForm = () => {
    if (
      !form.nombre_tienda ||
      !form.origen_producto ||
      !form.tipo_oferta ||
      !form.registro_marca ||
      !form.nombre ||
      !form.email ||
      !form.telefono ||
      !form.url_tienda
    ) {
      return false;
    }
    if (validateEmail(form.email) == true) return true;

    return false;
  };

  /*
  const handleChangeProductOrigin = async (e: any) => {
    console.log(e.target.value);
  };
  */

  /*
  const handleChangeRegistroMarca = async (e: any) => {
    console.log(e.target.value);
    console.log(e.target.checked);
    console.log(e.target.name);
    if (e.target.name == 'registro_marca_1') {
      setFormData({
        ...form,
        ['registro_marca_1']: e.target.value == 'on' ? 'SI' : '',
      });
      setFormData({
        ...form,
        ['registro_marca_2']: '',
      });
    } else {
      setFormData({
        ...form,
        ['registro_marca_2']: e.target.value == 'on' ? 'NO' : '',
      });
      setFormData({
        ...form,
        ['registro_marca_1']: '',
      });
    }
  };
  */

  const handleForm = async () => {
    setLoading(true);
    const isFormValid = validateForm();
    if (isFormValid) {
      setShowMessageVinculateAccount(true);
      try {
        const response = await axiosInstance.post('/seller_register', {
          nombre_tienda: form.nombre_tienda,
          descripcion: form.descripcion,
          origen_producto: form.origen_producto,
          tipo_oferta: form.tipo_oferta,
          registro_marca: form.registro_marca,
          nombre: form.nombre,
          telefono: form.telefono,
          email: form.email,
          url_tienda: form.url_tienda,
        });
        if (response.data.status == true) {
          addToast({
            id: 'login-toast',
            type: 'success',
            text: 'Vinculación de cuenta exitosa!',
            duration: 4000,
          });
          setIsVerifing(false);
          setIsVinculate(true);
          setShowMessageVinculateAccount(false);
        } else {
          setShowMessageVinculateAccount(false);
          addToast({
            id: 'login-toast',
            type: 'danger',
            text: 'Error Vinculando cuenta. Verifique sus datos.',
            duration: 4000,
          });
        }
      } catch (error) {
        console.log(error);
        setError(true);
        setShowMessageVinculateAccount(false);
      }
    } else {
      addToast({
        id: 'form-incomplete-toast',
        type: 'danger',
        text: 'Verifique sus datos.',
        duration: 4000,
      });
    }
    setLoading(false);
    return;
  };

  if (isVerifing)
    return (
      <div>
        <label>Verificando vinculación...</label>
      </div>
    );

  if (errorValidateStore)
    return (
      <div>
        <label>Error al verificar estado de la cuenta.</label>
      </div>
    );

  return (
    <Page maxWidth="700px" marginTop="18">
      {!isVerifing && !isVinculate ? (
        <>
          <Page.Header alignItems="center" title="Formulario de alta" />
          <Card>
            <Card.Header title="" />
            <Card.Body>
              <Box display="flex" flexDirection="column" gap="4" padding="2">
                <Text as="p">
                  <strong>NEGOCIO</strong>
                </Text>
                <FormField label="Nombre de tu tienda *">
                  <Input
                    name="nombre_tienda"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Descripción">
                  <Textarea
                    id="textarea"
                    name="descripcion"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Origen del Producto *">
                  <Select
                    appearance="neutral"
                    id="origen_producto"
                    name="origen_producto"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  >
                    <Select.Option
                      disabled
                      label="Seleccionar origen"
                      selected
                      value=""
                    />
                    <Select.Option
                      label="Todos son producidos en México"
                      value="Todos son producidos en México"
                    />
                    <Select.Option
                      label="Algunos son producidos en México"
                      value="Algunos son producidos en México"
                    />
                    <Select.Option
                      label="Algunos son producidos en el extranjero y vendido en México"
                      value="Algunos son producidos en el extranjero y vendido en México"
                    />
                    <Select.Option
                      label="Todos son producidos en el extranjero y vendido en México"
                      value="Todos son producidos en el extranjero y vendido en México"
                    />
                  </Select>
                </FormField>
                <FormField label="Tipo de Oferta *">
                  <Select
                    appearance="neutral"
                    id="tipo_oferta"
                    name="tipo_oferta"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  >
                    <Select.Option
                      disabled
                      label="Seleccionar tipo"
                      selected
                      value=""
                    />
                    <Select.Option
                      label="Vendo productos"
                      value="Vendo productos"
                    />
                    <Select.Option
                      label="Vendo servicios"
                      value="Vendo servicios"
                    />
                  </Select>
                </FormField>
                <FormField label="¿Cuentas con registro de marca en el IMPI? *">
                  <Select
                    appearance="neutral"
                    id="registro_marca"
                    name="registro_marca"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  >
                    <Select.Option
                      disabled
                      label="Seleccionar registro"
                      selected
                      value=""
                    />
                    <Select.Option label="SI" value="SI" />
                    <Select.Option label="NO" value="NO" />
                  </Select>
                  {/*
                  <Radio
                    name="registro_marca_1"
                    label="SI"
                    onChange={(event) => {
                      handleChangeRegistroMarca(event);
                    }}
                  />
                  <Radio
                    name="registro_marca_2"
                    label="NO"
                    onChange={(event) => {
                      handleChangeRegistroMarca(event);
                    }}
                  />
                  */}
                </FormField>
                <Text as="p">
                  <strong>INFORMACIÓN PERSONAL</strong>
                </Text>
                <FormField label="Nombre completo *">
                  <Input
                    name="nombre"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Teléfono de contacto *">
                  <Input
                    name="telefono"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Correo electrónico *">
                  <Input
                    name="email"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Link de tienda *">
                  <Input
                    name="url_tienda"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <Text as="p">
                  <strong>TÉRMINOS Y CONDICIONES</strong>
                </Text>
                <Box display="inline-flex" gap="2">
                  <Checkbox name="terminos-1" label="" />
                  <Text as="p">
                    {' '}
                    Acepto haber leído, comprendido y autorizado plenamente el{' '}
                    <a href="" target="_blank">
                      Convenio entre México Limited y creadores
                    </a>
                    .
                  </Text>
                </Box>
                <Box display="inline-flex" gap="2">
                  <Checkbox name="terminos-2" label="" />
                  <Text as="p">
                    {' '}
                    Acepto haber leído, comprendido y autorizado plenamente la{' '}
                    <a href="" target="_blank">
                      Declaración de privacidad y confidencialidad
                    </a>
                    .
                  </Text>
                </Box>
                <Box display="inline-flex" gap="2">
                  <Checkbox name="terminos-3" label="" />
                  <Text as="p">
                    {' '}
                    Acepto haber leído, comprendido y autorizado plenamente los{' '}
                    <a href="" target="_blank">
                      Términos y condiciones del marketplace
                    </a>
                    .
                  </Text>
                </Box>
              </Box>
            </Card.Body>
          </Card>
          <Box display="flex" justifyContent="flex-end" gap="4" marginTop="6">
            <Button onClick={handleGoToDashboard}>CANCELAR</Button>
            {loading ? (
              <Button appearance="primary">
                <Spinner size="small" color="neutral-background" />
              </Button>
            ) : (
              <Button onClick={handleForm} type="submit" appearance="primary">
                ENVIAR
              </Button>
            )}
          </Box>
          <Box
            display={showMessageVinculateAccount ? 'flex' : 'none'}
            justifyContent="center"
            gap="10"
            marginTop="2"
            marginBottom="6"
          >
            <Text as="p" fontSize="highlight">
              <p>Vinculando cuenta, por favor aguarde unos instantes...</p>
            </Text>
          </Box>
        </>
      ) : (
        <>
          {!isVerifing && isVinculate && !isApproved ? (
            <>
              <Page.Header alignItems="center" title="Sellers" />
              <Page.Body>
                <Layout columns="1">
                  <Layout.Section>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="4"
                      padding="4"
                    >
                      <Title textAlign="center" as="h3">
                        Estado: esperando aprobación de cuenta
                      </Title>
                      <Card>
                        <Card.Body>
                          <Text>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Vestibulum ullamcorper arcu tellus, vel
                            aliquet tortor faucibus at. Morbi sit amet augue id
                            risus sodales molestie in quis ante. Vivamus eu diam
                            eget turpis efficitur eleifend. Duis non metus
                            risus. In hendrerit laoreet velit at venenatis. Sed
                            turpis augue, posuere sit amet ligula non,
                            scelerisque pellentesque dolor. Praesent dignissim
                            tincidunt nulla, sit amet tristique neque lobortis
                            vitae. Etiam facilisis nibh odio, vitae pharetra est
                            tincidunt nec. Curabitur vitae facilisis erat.
                            Nullam luctus placerat neque, sit amet ultrices elit
                            hendrerit quis. Sed non mauris ultricies, congue est
                            nec, elementum risus. Suspendisse potenti.
                          </Text>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <Text>
                            Pellentesque molestie auctor pharetra. Vestibulum
                            egestas sit amet turpis eu efficitur. Nunc ut massa
                            vitae urna tincidunt eleifend id sit amet dolor.
                            Quisque ac nunc nec dolor consequat consectetur et
                            vel nulla. Sed posuere velit tortor, non vulputate
                            est fermentum et. Donec vulputate pretium egestas.
                            Nullam ornare metus in auctor tincidunt. Aliquam nec
                            lectus bibendum lectus tempor blandit non vitae
                            diam. Proin lobortis euismod consequat. Quisque
                            imperdiet gravida sapien, eu tristique lorem
                            fringilla nec. Nulla mattis lacinia eros, ut posuere
                            felis porttitor quis. Quisque sagittis lorem
                            fringilla vestibulum commodo. Orci varius natoque
                            penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Aliquam nibh erat, dictum id purus
                            sit amet, vehicula placerat elit. In et feugiat
                            velit, a viverra orci.
                          </Text>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <Text>
                            Duis non turpis eget lacus commodo luctus ac a
                            lectus. Nunc ut efficitur elit, nec volutpat tellus.
                            Donec non libero maximus, feugiat lectus in, varius
                            magna. Etiam in cursus purus. Morbi lacinia augue ut
                            lacus interdum, et hendrerit leo varius. Vestibulum
                            et metus et diam lacinia fringilla vel a odio.
                            Quisque interdum nulla non diam elementum bibendum.
                            Proin feugiat tellus diam, sed vehicula risus luctus
                            ut. Praesent et elementum lorem, nec scelerisque
                            sapien.{' '}
                          </Text>
                        </Card.Body>
                      </Card>
                    </Box>
                  </Layout.Section>
                </Layout>
              </Page.Body>
            </>
          ) : (
            <>
              {!isVerifing && isVinculate && isApproved ? (
                <>
                  <Page.Header alignItems="center" title="Sellers" />
                  <Page.Body>
                    <Layout columns="1">
                      <Layout.Section>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap="4"
                          padding="4"
                        >
                          <Title textAlign="center" as="h3">
                            Estado: Cuenta aprobada
                          </Title>
                          <Card>
                            <Card.Body>
                              <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Vestibulum ullamcorper arcu
                                tellus, vel aliquet tortor faucibus at. Morbi
                                sit amet augue id risus sodales molestie in quis
                                ante. Vivamus eu diam eget turpis efficitur
                                eleifend. Duis non metus risus. In hendrerit
                                laoreet velit at venenatis. Sed turpis augue,
                                posuere sit amet ligula non, scelerisque
                                pellentesque dolor. Praesent dignissim tincidunt
                                nulla, sit amet tristique neque lobortis vitae.
                                Etiam facilisis nibh odio, vitae pharetra est
                                tincidunt nec. Curabitur vitae facilisis erat.
                                Nullam luctus placerat neque, sit amet ultrices
                                elit hendrerit quis. Sed non mauris ultricies,
                                congue est nec, elementum risus. Suspendisse
                                potenti.
                              </Text>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Body>
                              <Text>
                                Pellentesque molestie auctor pharetra.
                                Vestibulum egestas sit amet turpis eu efficitur.
                                Nunc ut massa vitae urna tincidunt eleifend id
                                sit amet dolor. Quisque ac nunc nec dolor
                                consequat consectetur et vel nulla. Sed posuere
                                velit tortor, non vulputate est fermentum et.
                                Donec vulputate pretium egestas. Nullam ornare
                                metus in auctor tincidunt. Aliquam nec lectus
                                bibendum lectus tempor blandit non vitae diam.
                                Proin lobortis euismod consequat. Quisque
                                imperdiet gravida sapien, eu tristique lorem
                                fringilla nec. Nulla mattis lacinia eros, ut
                                posuere felis porttitor quis. Quisque sagittis
                                lorem fringilla vestibulum commodo. Orci varius
                                natoque penatibus et magnis dis parturient
                                montes, nascetur ridiculus mus. Aliquam nibh
                                erat, dictum id purus sit amet, vehicula
                                placerat elit. In et feugiat velit, a viverra
                                orci.
                              </Text>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Body>
                              <Text>
                                Duis non turpis eget lacus commodo luctus ac a
                                lectus. Nunc ut efficitur elit, nec volutpat
                                tellus. Donec non libero maximus, feugiat lectus
                                in, varius magna. Etiam in cursus purus. Morbi
                                lacinia augue ut lacus interdum, et hendrerit
                                leo varius. Vestibulum et metus et diam lacinia
                                fringilla vel a odio. Quisque interdum nulla non
                                diam elementum bibendum. Proin feugiat tellus
                                diam, sed vehicula risus luctus ut. Praesent et
                                elementum lorem, nec scelerisque sapien.{' '}
                              </Text>
                            </Card.Body>
                          </Card>
                          <Button
                            appearance="primary"
                            onClick={handleGoToConfig}
                          >
                            <Icon source={<CogIcon />} color="currentColor" />
                            IR A CONFIGURACIÓN
                          </Button>
                        </Box>
                      </Layout.Section>
                    </Layout>
                  </Page.Body>
                </>
              ) : (
                <>
                  <Page.Header alignItems="center" title="Formulario de alta" />
                  <Box display="flex" gap="4" justifyContent="center">
                    <Button appearance="primary">
                      <Icon source={<CogIcon />} color="currentColor" />
                      ERROR
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}
    </Page>
  );
};

export default PageDashboard;
